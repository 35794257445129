import { defineStore } from 'pinia';
import { h } from 'vue';
import { toast } from 'vue3-toastify';
import ToastMsg from '@/components/feedback/ToastMsg.vue';

const sharedState = {
    modal: null
}

export default defineStore('InterfaceStore', {
    state: () => sharedState,
    actions: {
        notify(message, type='success') {
            const props = {
                title: `${type}!`,
                message: message,
                position: toast.POSITION.TOP_CENTER
            };

            toast[type](h(ToastMsg, props));
        }
    }
})